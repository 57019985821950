import * as React from 'react'
import Template from "../components/template";
import "./media.css";
import "../components/tags.css";
import RoleIcon from "../components/icons/role";
import Photos from "../components/photos";

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "All"
        }
    }

    onSectionClick = filter => {
        this.setState({filter})
        return false
    }

    render() {
        const lang = this.props.lang || "en";
        const items = [
            {
                name: lang === "ru" ? "Надоело! Или три пути программиста" : "Tired of it! Or three ways for a programmer",
                platformName: lang === "ru" ? "Секреты ведущего программиста" : "Lead developer's secrets",
                platformLink: "https://www.youtube.com/channel/UCzB6c30QhbHOLK6A8mVsXDw",
                youtubeLink: "https://www.youtube.com/embed/oi6tULUCSaM",
                tags: ["Videos", "YouTube", "Russian"],
                date: "2020-04-12",
                role: "Speaker" // todo translate
            },
            {
                name: lang === "ru" ? "Как стать хорошим программистом" : "How to become a good programmer",
                platformName: lang === "ru" ? "Секреты ведущего программиста" : "Lead developer's secrets",
                platformLink: "https://www.youtube.com/channel/UCzB6c30QhbHOLK6A8mVsXDw",
                youtubeLink: "https://www.youtube.com/embed/kuGTu5z4Y7M",
                tags: ["Videos", "YouTube", "Russian"],
                date: "2020-04-09",
                role: "Speaker"
            },
            {
                name: lang === "ru" ? "Знакомство" : "Welcome",
                platformName: lang === "ru" ? "Секреты ведущего программиста" : "Lead developer's secrets",
                platformLink: "https://www.youtube.com/channel/UCzB6c30QhbHOLK6A8mVsXDw",
                youtubeLink: "https://www.youtube.com/embed/8txJaqiIkl0",
                tags: ["Videos", "YouTube", "Russian"],
                date: "2020-04-08",
                role: "Speaker"
            },
            {
                name: lang === "ru" ? "Фотографии" : "Photos",
                platformName: "Joker 2019",
                photos: "j2019",
                tags: ["Photos", "Conferences"],
                date: "2019-10-26",
                role: "Speaker"
            },
            {
                name: lang === "ru" ? "Интервью у стенда Pixonic" : "Pixonic stand interview",
                platformName: "Joker 2019",
                // platformLink: "https://www.youtube.com/watch?v=ZRXQn9CWO88&list=PLH-XmS0lSi_zgalbXwsytGNdAlNYmmE5C",
                youtubeLink: "https://www.youtube.com/embed/ZBTZhzG2E6E",
                tags: ["Videos", "Conferences", "Interviews", "Russian", "Pixonic", "AppMetr"],
                date: "2019-10-26",
                role: "Interviewee"
            },
            {
                name: lang === "ru" ? "In-house аналитическая система на Java" : "In-house analytical system in Java",
                platformName: "Joker 2019",
                youtubeLink: "https://www.youtube.com/embed/fflzTTIWuls",
                tags: ["Videos", "Conferences", "Russian", "Pixonic", "AppMetr"],
                date: "2019-10-26",
                role: "Speaker"
            },
            {
                name: lang ==="ru" ? "Открытие конференции" : "Conference opening",
                platformName: "Joker 2019",
                youtubeLink: "https://www.youtube.com/embed/RqhFPqTxNWw?start=788",
                tags: ["Videos", "Conferences", "Russian", "Pixonic"],
                date: "2019-10-25",
                role: "Speaker"
            },
            {
                name: lang === "ru" ? "Как мы сделали аналог WhatsApp в России в нулевые" : "How we made a WhatsApp-like app in Russia in the 2000s",
                platformName: "vc.ru",
                link: "https://vc.ru/life/64998-kak-my-sdelali-analog-whatsapp-v-rossii-v-nulevye",
                img: "https://vc.ru/cover/fb/c/64998/1555618562/cover.jpg",
                tags: ["Articles", "CD.RU", "City Chat", "CD Service", "Spacedoors"],
                date: "2019-04-19",
                role: "Mention"
            },
            {
                name: lang === "ru" ? "Фотографии" : "Photos",
                platformName: "Saint Highload++ 2019",
                platformLink: "https://www.youtube.com/watch?v=ZRXQn9CWO88&list=PLH-XmS0lSi_zgalbXwsytGNdAlNYmmE5C",
                photos: "shl2019",
                tags: ["Photos", "Conferences"],
                date: "2019-04-08",
                role: "Speaker"
            },
            {
                name: lang === "ru" ? "Как мы обрабатываем миллиард событий в сутки без ClickHouse" : "How we process a billion events per day without ClickHouse",
                platformName: "Saint Highload++ 2019",
                platformLink: "https://www.youtube.com/watch?v=ZRXQn9CWO88&list=PLH-XmS0lSi_zgalbXwsytGNdAlNYmmE5C",
                youtubeLink: "https://www.youtube.com/embed/F_moPIR-cNU",
                tags: ["Videos", "Conferences", "Russian", "Pixonic", "AppMetr"],
                date: "2019-04-08",
                role: "Speaker"
            },
            {
                name: lang === "ru" ? "Проблема использования CompletableFuture в нескольких потоках и её решение" : "The problem of using CompletableFuture in multiple threads and solution",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/325730/",
                img: "https://habrastorage.org/r/w1560/files/d87/570/a6a/d87570a6ab2f466897dcb5408686c66d.jpg",
                imgWidth: 250,
                tags: ["Articles"],
                date: "2017-04-08",
                role: "Author"
            },
            {
                name: lang === "ru" ? "OMF — микроформат для email. Опыт использования в сервисе чатов" : "OMF is a microformat for email. Experience of using the chat service",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/102184/",
                img: "https://habrastorage.org/getpro/habr/post_images/6f2/d9a/c17/6f2d9ac172623ebfa179c0580561fbed.gif",
                imgWidth: 296,
                tags: ["Articles", "Chatovod.ru"],
                date: "2010-08-19",
                role: "Author"
            },
            {
                name: lang ==="ru" ? "Новый провайдер OpenID" : "New OpenID provider",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/26017/",
                img: "https://habrastorage.org/r/w1560/getpro/geektimes/post_images/a08/a3c/2c0/a08a3c2c02d7a06a7e49adc9e92651aa.png",
                imgWidth: 200,
                tags: ["Articles", "BestPersons.ru"],
                date: "2009-05-23",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Александр Харитонов. BestPersons.ru – Лучшие люди" : "Alexander Kharitonov. BestPersons.ru - Best people",
                platformName: "catalogr.ru",
                link: "http://catalogr.ru/interview/bestpersons-ru/",
                img: "/static/i/catalogr.gif",
                imgWidth: 125,
                tags: ["Interviews", "BestPersons.ru"],
                date: "2008-09-10",
                role: "Interviewee"
            },
            {
                name: lang === "ru" ? "Лента друзей и кросспостинг в мобильном" : "Friends feed and cross-posting in mobile",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/25791/",
                img: "https://habrastorage.org/r/w1560/getpro/geektimes/post_images/83d/85b/b55/83d85bb5531f979af9e4f6441478431a.png",
                imgWidth: 296,
                tags: ["Articles", "BestPersons.ru"],
                date: "2008-05-20",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Официально: большое обновление BestPersons.ru" : "Officially: Big Update BestPersons.ru",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/25322/",
                img: "https://habrastorage.org/getpro/geektimes/post_images/a52/70d/d10/a5270dd10a5abc4d514d2d7ea96bd615.png",
                imgWidth: 145,
                tags: ["Articles", "BestPersons.ru"],
                date: "2008-05-13",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Lifestream-информер" : "Lifestream informer",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/22633/",
                img: "https://habrastorage.org/getpro/geektimes/post_images/b3a/99c/6ec/b3a99c6ec06c6070051257a2dde38cb4.jpg",
                imgWidth: 96,
                tags: ["Articles", "BestPersons.ru"],
                date: "2008-03-31",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Google Social Graph теперь в России" : "Google Social Graph is now in Russia",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/22101/",
                img: "https://habrastorage.org/r/w1560/getpro/geektimes/post_images/b79/869/6d1/b798696d134b1702017900b88c0e21b7.png",
                imgWidth: 296,
                tags: ["Articles", "BestPersons.ru"],
                date: "2008-03-21",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Новый социальный toolbar для Firefox" : "New social toolbar for Firefox",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/21006/",
                img: "https://habrastorage.org/r/w1560/getpro/habr/post_images/c85/bdd/d7d/c85bddd7d53c4be1b4e5841e0978bbfa.png",
                imgWidth: 296,
                tags: ["Articles", "BestPersons.ru"],
                date: "2008-02-28",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Александр Харитонов, Best Persons: \"Задача проекта — объединение социальных сетей\"" : "Alexander Kharitonov, Best Persons: \"The task of the project is to unite social networks\"",
                platformName: "TelNews.ru",
                link: "https://web.archive.org/web/20080314074240/https://telnews.ru/event/16534",
                img: "https://web.archive.org/web/20080314074240im_/https://telnews.ru/i/logo-common.gif",
                imgWidth: 191,
                tags: ["Interviews", "BestPersons.ru"],
                date: "2008-02-02",
                role: "Interviewee"
            },
            {
                name: lang === "ru" ? "Объединяя социальные сети — bestpersons.ru. Новые возможности." : "Uniting social networks - bestpersons.ru. New opportunities.",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/18413/",
                img: "https://habrastorage.org/r/w1560/getpro/habr/post_images/faf/4bc/2e2/faf4bc2e288443cd977b5e5b09406ec7.png",
                imgWidth: 296,
                tags: ["Articles", "BestPersons.ru"],
                date: "2008-01-07",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Объединяя социальные сети" : "Uniting social networks",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/17701/",
                img: "https://habrastorage.org/getpro/geektimes/post_images/15a/3e2/a3a/15a3e2a3ad37be2f73b49fd343af9683.png",
                imgWidth: 296,
                tags: ["Articles", "BestPersons.ru"],
                date: "2007-12-18",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Старые картины" : "Old paintings",
                platformName: "Home",
                photos: "old_paintings",
                tags: ["Paintings"],
                date: "",
                role: "Author"
            },
            {
                name: lang === "ru" ? "Первый опыт с Google Maps API" : "First experience with Google Maps API",
                platformName: "Habr.com",
                link: "https://habr.com/ru/post/15256/",
                img: "https://habrastorage.org/r/w1560/getpro/geektimes/post_images/22f/566/ce3/22f566ce38589a6944816eb8571adbad.png",
                imgWidth: 296,
                tags: ["Articles", "Pushkino.org"],
                date: "2007-10-21",
                role: "Author"
            },
        ]
        const itemsEl = items.filter(p => {
            return !this.state.filter || this.state.filter === "All" || p.tags.indexOf(this.state.filter) >= 0;
        }).map((p, index) => {
            let company
            if (p.platformLink) {
                company = <a rel="nofollow" target="_blank" href={p.platformLink}>{p.platformName}</a>
            } else if (p.platformName) {
                company = <React.Fragment>{p.platformName}</React.Fragment>
            }
            if (company) company = <React.Fragment> @ {company}</React.Fragment>
            let tags
            if (p.tags) {
                const tagEls = p.tags.map(tag => <span key={tag}>{tag}</span>)
                tags = <div className="tags">
                    {tagEls}
                </div>
            }

            let role = "";
            if (p.role) {
                role = <div className="text-sm inline-flex items-center text-gray-500" title="Role"><RoleIcon className="inline w-4 mr-1 fill-current"/>{p.role}</div>
            }
            let body;
            if (p.youtubeLink)
                body = <div className="aspect-w-16 aspect-h-9">
                    <iframe src={p.youtubeLink}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            else if (p.img) {
                body = <img src={p.img} alt="img" width={p.imgWidth||""}/>
                if (p.link)
                    body = <div><a href={p.link} target="_blank" rel="nofollow">{body}</a></div>
            } else if (p.photos) {
                body = <Photos name={p.photos}/>
            } else
                body = p.body

            let title = p.name
            if (p.link) {
                title = <a href={p.link} target="_blank" rel="nofollow">{title}</a>
            }

            return <div className="space-y-2" key={"media-item-" + index}>
                <div className="flex items-baseline">
                    <div className="text-xl flex-grow">
                        <span className="font-bold">{title}</span>{company}
                    </div>
                    <div className="text-gray-400 text-xs sm:text-sm whitespace-nowrap ml-1">{p.date}</div>
                </div>
                {role}
                {body}
                {tags}
            </div>
        })

        const sections = ["All", "Videos", "Conferences", "Interviews", "Articles", "Photos", "Paintings"]
        const sectionEls = sections.map(section => {
            const clName = section === this.state.filter ? "filter-active" : null
            return <a key={section} className={clName} onClick={() => this.onSectionClick(section)}>{section}</a>
        })

        return (
            <Template page="media" wide={true} title="Media & Publications">
                <div className="flex flex-col items-center gap-6">
                    <div className="filter-items">
                        {sectionEls}
                    </div>
                    <div className="media-items">
                        {itemsEl}
                    </div>
                </div>
            </Template>
        )
    }
}

export default Page
